<template>
  <div class="home">
    <div class="top-banner wow bounceInLeft">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide swipe-items" v-for="(item,index) in bannerList" :key="index">
            <img :src="item.imgUrl" class="banner-img" alt />
            <!-- <el-image class="banner-img" :src="item.imgUrl" /> -->
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
    <div class="new-area-wrap">
      <div class="new-area">
        <div class="new-top">
          <div class="new-tab">
            <div class="new-tab-i" :class="[index==newIndex?'new-active':'']" v-for="(item,index) in newList" :key="index" @click="newTab(item,index)">
              <span>{{item.name}}</span>
              <img v-if="index!=newList.length-1" src="../assets/images/line.png" alt="">
            </div>
          </div>
          <div class="more-btn" @click="jumpPage('/groupDynamics')">查看更多</div>
        </div>
        <div class="new-content" v-if="firstArticle && firstArticle.post_title">
          <div class="new-l wow bounceInLeft">
            <div class="thumbnail">
              <img :src="firstArticle.thumbnail" alt="">
            </div>
            <div class="new-item-first" @click="jumpArticleDetail(firstArticle)">
              <div class="first-l">
                <span>01</span>
                <b>{{firstArticle.published_time.substring(0,10)}}</b>
              </div>
              <div class="first-r">
                <div class="first-tit">{{ firstArticle.post_title}}</div>
                <div class="first-desc">{{ firstArticle.post_excerpt}}</div>
              </div>
            </div>
          </div>
          <div class="new-r wow bounceInRight">
            <div class="new-item" v-for="(item,index) in articleList" :key="index" @click="jumpArticleDetail(item)">
              <div class="first-l">
                <span>0{{ index+2 }}</span>
                <b>{{ item.published_time.substring(0,10)}}</b>
              </div>
              <div class="first-r">
                <div class="first-tit">{{  item.post_title }}</div>
                <div class="first-r-line"></div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <div class="product-wrap">
      <div class="product-box">
        <div class="title-box wow bounceInLeft">
          <div class="title">产品介绍</div>
          <div class="small-title">Product Introduction</div>
        </div>
        <div class="product-swiper-container wow bounceInRight">
          <div class="swiper-wrapper">
            <div class="swiper-slide product-items" @click="jumpPage('/dapaidui')">
              <img src="https://gwcdn.fuhua95.com/pc/home/product1.png" alt="">
            </div>
            
            <!-- <div class="swiper-slide product-items" @click="jumpPage('/jiaosu')">
              <img src="https://gwcdn.fuhua95.com/pc/home/product2.png" alt="">
            </div> -->
            <div class="swiper-slide product-items" @click="jumpPage('/fengchao')">
              <img src="https://gwcdn.fuhua95.com/pc/home/product3.png" alt="">
            </div>
         
            <div class="swiper-slide product-items" @click="jumpPage('/longxiang')">
              <img src="https://gwcdn.fuhua95.com/pc/home/product5.png" alt="">
            </div>
            
          </div>
          <div class="product-swiper-button-prev"></div>
          <div class="product-swiper-button-next"></div>
          <!-- 如果需要分页器 -->
          <div class="product-swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="hot-game-wrap">
      <div class="hot-game">
        <div class="title-box wow bounceInLeft">
          <div class="title">热门游戏</div>
          <div class="small-title">Popular Games</div>
        </div>
        <div class="game-swiper-container wow bounceInRight">
          <div class="game-swiper-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide game-items" v-for="(item,index) in gameList" :key="index">
                <div class="game-items-warp">
                  <img :src="item.imgUrl" alt="">
                </div>
              </div>
             
            </div>
          </div>
          
          <div class="game-swiper-button-prev"></div>
          <div class="game-swiper-button-next"></div>
          <!-- 如果需要分页器 -->
          <div class="game-swiper-pagination"></div>
        </div>
      </div>
    </div>

    <div class="gongyi-wrap">
      <div class="hot-game">
        <div class="title-box gongyi-title-box wow bounceInLeft">
          <div class="title">爱心公益，践行企业社会责任</div>
          <div class="small-title">Caring for public welfare and practicing corporate social responsibility</div>
        </div>
        <div class="gongyi-swiper-container wow bounceInRight">
          <div class="gongyi-swiper-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide gongyi-items" v-for="(item,index) in gongyiList" :key="index">
                <img :src="item.imgUrl" alt="">
                <div class="gongyi-title">
                  <p>{{ item.title }}</p>
                  <p>{{ item.title2 }}</p>
                </div>
              </div>
             
            </div>
          </div>
          
          <div class="gongyi-swiper-button-prev"></div>
          <div class="gongyi-swiper-button-next"></div>
          <!-- 如果需要分页器 -->
          <div class="gongyi-swiper-pagination"></div>
        </div>
      </div>
    </div>

   

    <div class="partner-box">
      <div class="partner-title wow bounceInLeft">
        <div class="title">合作伙伴</div>
        <div class="small-title">Cooperative Partners</div>
      </div>
      <div class="partners-list">
        <div class="partners-item wow bounceInRight" v-for="(item,index) in partnersList" :key="index">
          <img :src="item.imgUrl" alt="">
        </div>
      </div>
      <div class="child-title wow bounceInLeft">
        <div class="title">旗下子公司</div>
        <div class="small-title">Subsidiary Companies</div>
      </div>
      <div class="child-list">
        <div class="child-item wow bounceInRight" v-for="(item,index) in childList" :key="index">
          <img :src="item.imgUrl" alt="">
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getCurrentInstance,
  ref,
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted
} from "vue";
import qs from "qs";
import homeApi from "../api/api.js";
import axios from "axios";
import { useRouter } from "vue-router";
export default {
  name: "HomeView",
  setup() {
    const data = reactive({
      bannerList: [
        {
          name: "banner1",
          imgUrl:'https://gwcdn.fuhua95.com/pc/banner1.jpg'
          // imgUrl: require("../assets/images/banner1.jpg")
        },
      ],
      newList:[],
      gameList:[
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game1.png',
          iindex:1
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game2.png',
          iindex:2
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game3.png',
          iindex:3
        },
        
        
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game4.png',
          iindex:4
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game5.png',
          iindex:5
        },
        
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game6.png',
          iindex:6
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game7.png',
          iindex:7
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game8.png',
          iindex:8
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game9.png',
          iindex:9
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game10.png',
          iindex:10
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game11.png',
          iindex:11
        },
        
        
      ],
      gongyiList:[
        {
          //imgUrl: require("../assets/images/home/gongyi10.png"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi10.png',
          title:'我司向丰泽区教育发展基金会捐赠300万基金',
          title2:'吴克华总裁出席会议并接受政府授牌',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi11.png"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi11.png',
          title:'我司与泉州市妇联联合开展“友好泉州·牵手童行”小桔灯',
          title2:'关爱困境儿童“结对子”公益活动',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi12.png"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi12.png',
          title:'我司参与市残联、民建泉州联合举行“关爱星宝贝”结对帮扶活动，',
          title2:'筹措资金捐赠给孤独症儿童家庭',
        },

        {
          //imgUrl: require("../assets/images/home/gongyi1.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi1.jpg',
          title:'与泉州市妇联联合开展“关爱星星的孩子”公益活动'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi2.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi2.jpg',
          title:'我司执行总裁林桂清带领我司妇联关爱孤独症儿童获得盛达',
          title2:'康复启能中心送出的“关爱星星儿童，弘扬慈善美德”表彰',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi3.jpg"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/gongyi3.jpg',
          title:'吴克华总裁向峡阳中心小学捐赠款物',
          title2:'叶盛松校长上台接受捐赠',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi4.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi4.jpg',
          title:'我司党支部书记吴水生为峡阳中心小学优秀学生及家庭困难的学生代表送上奖学金、助学金及学习礼包'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi5.jpg"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/gongyi5.jpg',
          title:'陈正沿常务副总裁接受屏山镇副镇长赖德良代表校方',
          title2:'献上的捐赠证书',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi6.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi6.jpg',
          title:'吴克华总裁向石城屏山中心小学捐赠款物',
          title2:'屏山中心小学党支部副书记邓金田上台接受捐赠',
          
        },
      ],
      newIndex:0,
      partnersList:[
        {
          imgUrl: require("../assets/images/home/partners5.png"),
          //imgUrl:'https://gwcdn.fuhua95.com/pc/home/partners5.png',
        },
        {
          imgUrl: require("../assets/images/home/partners10.png"),
          //imgUrl:'https://gwcdn.fuhua95.com/pc/home/partners5.png',
        },
        {
          imgUrl: require("../assets/images/home/kaiying.png"),
        },
        {
          imgUrl: require("../assets/images/home/partners12.png"),
          //imgUrl:'https://gwcdn.fuhua95.com/pc/home/partners5.png',
        },


        {
          //imgUrl: require("../assets/images/home/partners7.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/partners7.png',
        },
        {
          //imgUrl: require("../assets/images/home/partners6.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/partners6.png',
        },
        {
          imgUrl: require("../assets/images/home/partners13.png"),
        },
        
        {
          //imgUrl: require("../assets/images/home/partners8.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/partners8.png',
        },
        
        
        
        

        
        
      ],
      childList:[
        {
          imgUrl: require("../assets/images/home/partners11.png"),
        },
        {
          //imgUrl: require("../assets/images/home/child2.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/child2.png',
        },
        {
         // imgUrl: require("../assets/images/home/child3.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/child3.png',
        },
        {
          //imgUrl: require("../assets/images/home/child1.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/child1.png',
        },
        
        
        {
          //imgUrl: require("../assets/images/home/child4.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/child4.png',
        },
        {
          //imgUrl: require("../assets/images/home/child5.png"),
          imgUrl:'https://gwcdn.fuhua95.com/pc/home/child5.png',
        },
        
      ],
      articleList:[],
      firstArticle:{}

    });

    //获取文章分类
    const getTypeList = () => {
      
      homeApi.articleTypeApi().then(res => {
        console.log(res);
        console.log("666res");
        if (res.code == 1) {
          // data.bannerList = res.data[0].items
          let arr = res.data.filter((item) => {
            return item.id<5
          })

          let newArr = arr.sort((a,b)=> {
            return - (a.list_order - b.list_order)
          })
          console.log(newArr,'newArr===')
          data.newList = arr
          getNewList(arr[0].id)
        }
      });
    }
    //获取文章列表
    const getNewList = (id) => {
      //?page=2&limit=5
      let params = {
        page:1,
        limit:10
      }
      homeApi.getNewListApi(id,params).then(res => {
        console.log(res)
        console.log("666res")
        if(res.code == 1){
          
          let arr = res.data.list.data
          data.firstArticle = res.data.list.data[0]
          let newArr = []
          arr.forEach((element,index) => {
            if(index>0 && index<5){
              newArr.push(element)
            }
          });
          data.articleList = newArr
        }
      })
    }
    // 跳转文章详情
    const router = useRouter();
    
    const jumpPage = (path) => {
      router.push({
          path: path,
         
      });
    }
    const jumpArticleDetail = (item) => {
      if(item.type == 1){
        window.open(item.url)
      }else{
        router.push({
            path: "/groupDynamicsDetail",
            query:{
              id:item.id
            }
        });
      }
      
    }

    onMounted(() => {
      getTypeList()
      const mySwiper = new Swiper(".swiper-container", {
        autoplay: 5000,
        // loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:"fade",
        // effect:"cube",
        // 如果需要分页器
        pagination: ".swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev"
      });
      const productSwiper = new Swiper(".product-swiper-container", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:"fade",
        // effect:"cube",
        // 如果需要分页器
        pagination: ".product-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".product-swiper-button-next",
        prevButton: ".product-swiper-button-prev"
      });

      const gameSwiper = new Swiper(".game-swiper-box", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        
        // 如果需要分页器
        pagination: ".game-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".game-swiper-button-next",
        prevButton: ".game-swiper-button-prev",
        // onSlideChangeEnd: function(swiper){
        //   //console.log(swiper.activeIndex)
        //   // let index = swiper.activeIndex-1
        //   // if(index == -1){
        //   //   index = 1
        //   // }
        //   // if(index == 2){
        //   //   index = 0
        //   // }
        //   // data.gameBg = data.gameList[index].bgUrl
        //   console.log(index)

          
        // }
      });

      const gongyiSwiper = new Swiper(".gongyi-swiper-box", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        
        // 如果需要分页器
        pagination: ".gongyi-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".gongyi-swiper-button-next",
        prevButton: ".gongyi-swiper-button-prev",
        
      });
      
      getBannerList();

     
    });

    onMounted(() => {
      const wow = new WOW({
        boxClass: 'wow',            // animated element css class (default is wow)
        animateClass: 'animated',   // animation css class (default is animated)
        offset: 0,                  // distance to the element when triggering the animation (default is 0)
        mobile: true,               // trigger animations on mobile devices (default is true)
        live: true,                 // act on asynchronously loaded content (default is true)
        callback: function (box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null,      // optional scroll container selector, otherwise use window
      })
      wow.init()
    });

    const getBannerList = () => {
      let id = 1;
      homeApi.homeBannerListApi(id).then(res => {
        console.log(res);
        console.log("666res");
        if (res.code == 1) {
          // data.bannerList = res.data[0].items
        }
      });
    };

    const newTab = (item,index) => {
       data.newIndex = index
       console.log(item)
       getNewList(item.id)
    }

    return {
      ...toRefs(data),
      newTab,
      jumpArticleDetail,
      jumpPage
    };
  }
};
</script>
<style lang="scss">
.top-banner {
  .swiper-pagination-bullets {
    bottom: 50px;
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #ffffff;
    opacity: 0.7;
    transition: width 0.5s ease-in-out;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
.product-swiper-pagination{
  
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
.hot-game-wrap{
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
.gongyi-wrap{
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
</style>
<style scoped lang="scss">
.home {
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  .top-banner {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .swiper-container {
      width: 100%;
      height: 800px;
    }

    .banner-swipe {
      width: 100%;
    }
    .demonstration {
      color: var(--el-text-color-secondary);
    }

    .el-carousel__item h3 {
      color: #475669;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
      text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .swipe-items {
      width: 100%;
      height: 800px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .tit {
      font-size: 88px;
      font-family: "微软雅黑";
      font-weight: 600;
      color: #f3f3f3;
    }
    .about-btn {
      width: 500px;
      height: 97px;
      background: rgba(30, 103, 252, 0.5);
      border-radius: 5px 5px 5px 5px;
      font-size: 40px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .new-area-wrap{
    width: 100%;
    background: #FFFFFF;
    padding-top: 56px;
    padding-bottom: 30px;
  }
  .new-area{
    width: 1200px;
    margin: 0 auto;
   
    
    .new-top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .new-tab{
        display: flex;
        align-items: center;
        .new-tab-i{
          display: flex;
          align-items: center;
          cursor: pointer;
          
          span{
            font-size: 26px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: rgba(153,153,153,0.9);
          }
          img{
            width: 8px;
            height: 27px;
            margin: 0 17px;
          }
        }
        .new-active{
          background: url('../assets/images/line-bg.png') no-repeat 0 47px;
          background-size: 176px;
          
          span{
            font-size: 44px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
      }
      .more-btn{
        width: 180px;
        height: 52px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #1E67FC;
        font-size: 20px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: #1E67FC;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .new-content{
      display: flex;
      margin-top: 47px;
      .new-l{
        width: 559px;
        margin-right: 65px;
        .thumbnail{
          width: 559px;
          height: 391px;
          background: url('../assets/images/home/new-bg.png') no-repeat;
          background-size: 100% 100%;
          padding: 14px 12px 23px 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .new-item-first{
          display: flex;
          margin-top: 40px;
          cursor: pointer;
          .first-l{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 33px;
            width: 78px;
            span{
              font-size: 34px;
              font-family: Font Awesome 6 Brands-Regular, Font Awesome 6 Brands;
              font-weight: 400;
              color: rgba(30,103,252,0.9);
            }
            b{
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(153,153,153,0.9);
              margin-top: 11px;
            }
          }
          .first-r{
            flex: 1;
            .first-tit{
              font-size: 20px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(51,51,51,0.9);
              line-height: 30px;
              overflow:hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            .first-desc{
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              margin-top: 10px;
              overflow:hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .new-r{
        flex: 1;
        .new-item{
          display: flex;
          margin-bottom: 50px;
          cursor: pointer;
          .first-l{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 33px;
            width: 78px;
            span{
              font-size: 34px;
              font-family: Font Awesome 6 Brands-Regular, Font Awesome 6 Brands;
              font-weight: 400;
              color: #333333;
            }
            b{
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(153,153,153,0.9);
              margin-top: 11px;
            }
          }
          .first-r{
            display: flex;
            flex: 1;
            flex-direction: column;
            .first-tit{
              font-size: 20px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(51,51,51,0.9);
              line-height: 30px;
              overflow:hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            .first-r-line{
              width: 100%;
              height: 0px;
              opacity: 1;
              border: 1px solid #DFDFDF;
              margin-top: 40px;
            }
          }
        }
      }
    }
  }

  .product-wrap{
    height: 700px;
    width: 100%;
    background: #F6FBFF;
    position: relative;
    .product-box{
      width: 100%;
      
      .title-box{
        display: flex;
        align-items: flex-end;
        width: 1200px;
        margin: 0 auto;
        position: absolute;
        top: 55px;
        left: 50%;
        margin-left: -600px;
        .title{
          font-size: 44px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: url('../assets/images/line-bg.png') no-repeat 0 47px;
          background-size: 176px;
        }
        .small-title{
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-left: 30px;
        }
      }
      
      .product-swiper-container{
        width: 100%;
        overflow: hidden;
        position: relative;
        .product-swiper-pagination{
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -50px;
        }
        .product-swiper-button-prev{
          width: 66px;
          height: 66px;
          background: url('../assets/images/home/prev.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 50%;
          margin-left: -750px;
          top: 50%;
          margin-top: -66px;
          cursor: pointer;
          z-index: 999999;
        }
        .product-swiper-button-next{
          width: 66px;
          height: 66px;
          background: url('../assets/images/home/next.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 50%;
          margin-right: -750px;
          top: 50%;
          margin-top: -66px;
          cursor: pointer;
          z-index: 999999;
        }
        .swiper-wrapper{
          .product-items-warp{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            
          }
          .product-items{
            display: flex;
            img{
              width: 1200px;
              margin: 0 auto;
              height: 700px;
            }
          }
          

            
        }
      }
    }
  }

  .hot-game-wrap{
    height: 1000px;
    width: 100%;
    // background: url('../assets/images/home/game-bg.png') no-repeat;
    background: url('https://gwcdn.fuhua95.com/pc/home/game-bg.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 60px;
    .hot-game{
      width: 1200px;
      margin: 0 auto;
      .title-box{
        display: flex;
        align-items: flex-end;
        margin-bottom: 85px;
        .title{
          font-size: 44px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: url('../assets/images/line-bg.png') no-repeat 0 47px;
          background-size: 176px;
        }
        .small-title{
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-left: 30px;
        }
      }
      .game-swiper-container{
        width: 1200px;
        height: 675px;
        position: relative;
        .game-swiper-box{
          width: 1200px;
          height: 675px;
          overflow: hidden;
        }
        .game-items{
          width: 1200px;
          height: 675px;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .game-swiper-button-prev{
          width: 66px;
          height: 66px;
          background: url('../assets/images/home/prev.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: -144px;
          top: 50%;
          margin-top: -33px;
          cursor: pointer;
          z-index: 999999;
        }
        .game-swiper-button-next{
          width: 66px;
          height: 66px;
          background: url('../assets/images/home/next.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: -144px;
          top: 50%;
          margin-top: -33px;
          cursor: pointer;
          z-index: 999999;
        }
        .game-swiper-pagination{
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
        }
      }
    }
  }

  .gongyi-wrap{
    height: 980px;
    width: 100%;
    background: #F6FBFF;
    background-size: 100% 100%;
    padding-top: 60px;
    .hot-game{
      width: 1200px;
      margin: 0 auto;
      .title-box{
        display: flex;
        margin-bottom: 85px;
        width: 948px;
        margin: 0 auto;
        margin-bottom: 50px;
        .title{
          font-size: 44px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: url('../assets/images/line-bg.png') no-repeat 0 47px;
          background-size: 176px;
        }
        .small-title{
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-top: 13px;
        }
      }
      .gongyi-title-box{
        display: flex;
        flex-direction: column;
      }
      .gongyi-swiper-container{
        width: 1200px;
        height: 627px;
        position: relative;
        .gongyi-swiper-box{
          width: 948px;
          height: 627px;
          overflow: hidden;
          margin: 0 auto;
        }
        .gongyi-items{
          width: 948px;
          height: 627px;
          overflow: hidden;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          .gongyi-title{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 16px 24px;
            background: rgba(0,0,0,0.5);
            font-size: 30px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 38px;
            text-align: center;
          }
        }
        .gongyi-swiper-button-prev{
          width: 66px;
          height: 66px;
          background: url('../assets/images/home/prev.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: -144px;
          top: 50%;
          margin-top: -33px;
          cursor: pointer;
          z-index: 999999;
        }
        .gongyi-swiper-button-next{
          width: 66px;
          height: 66px;
          background: url('../assets/images/home/next.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: -144px;
          top: 50%;
          margin-top: -33px;
          cursor: pointer;
          z-index: 999999;
        }
        .gongyi-swiper-pagination{
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
        }
      }
    }
  }

 
  .partner-box{
    width: 1200px;
    height: 800px;
    //background: url('../assets/images/home/partner-bg.png') no-repeat;
    background: url('https://gwcdn.fuhua95.com/pc/home/partner-bg.png') no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 146px;
    margin-bottom: 146px;
    position: relative;
    padding: 107px 60px 97px;
    .partner-title{
      display: flex;
      align-items: flex-end;
      position: absolute;
      left:69px;
      top: -24px;
      .title{
        font-size: 44px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        background: url('../assets/images/line-bg.png') no-repeat 0 47px;
        background-size: 176px;
      }
      .small-title{
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 30px;
      }
    }
    .partners-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .partners-item{
        width: 260px;
        height: 150px;
        margin-bottom: 20px;
        position: relative;
        
        img{
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
          z-index: 10;
        }
        img:hover {
          transform: scale(1.04);
          z-index: 100;
        }
      }
    }
    .child-title{
      display: flex;
      align-items: flex-end;
      margin-bottom: 40px;
      margin-top: 10px;
      .title{
        font-size: 44px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        background: url('../assets/images/line-bg.png') no-repeat 0 47px;
        background-size: 217px;
      }
      .small-title{
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 30px;
      }
    }
    .child-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .child-item{
        width: 170px;
        height: 124px;
        margin-bottom: 20px;
        position: relative;
        img{
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
          z-index: 10;
        }
        img:hover {
          transform: scale(1.04);
          z-index: 100;
        }
      }
    }
  }
  
}
</style>
