import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/fengchao',
    name: 'fengchao',
    component: () => import(/* webpackChunkName: "about" */ '../views/fengchao.vue')
  },
  {
    path: '/jiaosu',
    name: 'jiaosu',
    component: () => import(/* webpackChunkName: "about" */ '../views/jiaosu.vue')
  },
  {
    path: '/longxiang',
    name: 'longxiang',
    component: () => import(/* webpackChunkName: "about" */ '../views/longxiang.vue')
  },
  {
    path: '/dapaidui',
    name: 'dapaidui',
    component: () => import(/* webpackChunkName: "about" */ '../views/dapaidui.vue')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/joinUs.vue')
  },
  {
    path: '/groupDynamics',
    name: 'groupDynamics',
    component: () => import(/* webpackChunkName: "about" */ '../views/groupDynamics.vue')
  },
  {
    path: '/groupDynamicsDetail',
    name: 'groupDynamicsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/groupDynamicsDetail.vue')
  },
  {
    path: '/investor',
    name: 'investor',
    component: () => import(/* webpackChunkName: "about" */ '../views/investor.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')
  },
  {
    path: '/joinDetail',
    name: 'joinDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/joinDetail.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
