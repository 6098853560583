<template>
  <div class="header">
    <div class="header-wrap">
      <div class="logo">
        <router-link to="/">
          <img src="https://gwcdn.fuhua95.com/pc/logo.png" />
        </router-link>
      </div>
      <div class="r-menu">
        <router-link to="/" class="item-menu">首页</router-link>
        <router-link to="/about" class="item-menu">集团概述</router-link>
        <div  class="item-menu item-menu-product" @mouseover="showMenu = true" @mouseleave="showMenu = false">
          <span>产品中心</span>
          <!-- v-if="showMenu" -->
          <div class="product-list" v-if="showMenu">
            <div class="product-list-item" @click="jumpPage('/dapaidui')">宇宙大派队</div>
            <!-- <div class="product-list-item" @click="jumpPage('/jiaosu')">脚速旅行</div> -->
            <div class="product-list-item" @click="jumpPage('/fengchao')">源之蜂巢数据库</div>
            
            <div class="product-list-item" @click="jumpPage('/longxiang')">龍翔手游</div>
            
          </div>
          
        </div>
        <router-link to="/groupDynamics" class="item-menu">集团动态</router-link>
        <a class="item-menu" href="https://shop.fuhua52.com/" target="_blank">周边手办</a>
        <router-link to="/joinUs" class="item-menu">联系我们</router-link>
        <!-- <router-link to="/contactUs" class="item-menu">联系我们</router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { useRouter } from "vue-router";
import {
  getCurrentInstance,
  watch,
  ref,
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted
} from "vue";
export default {
  name: "header",
  components: {},
  setup() {
    const data = reactive({
      showMenu:false,
      isProduct:false
    })
    const router = useRouter();
    const jumpPage = (path) => {
      data.showMenu = false
      router.push({
          path:path,
         
      });
    }

    watch(
      () => router.currentRoute.value,
      (newValue) => {
        document.body.scrollIntoView()
        if(newValue.path == '/product'){
          data.isProduct = true
        }else{
          data.isProduct = false
        }
        console.log('newValue',newValue)
      },
      { immediate: true }
    )
    return {
      ...toRefs(data),
      jumpPage
    }
  }
};
</script>
<style scoped lang="scss">


.header {
  height: 80px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.1);
  .header-wrap {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      width: 184px;
      height: 58px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .r-menu {
      display: flex;
      align-items: center;
      .item-menu {
        margin-left: 50px;
        font-size: 18px;
        font-family: "微软雅黑";
        font-weight: 400;
        color: #333333;
        height: 80px;
        line-height: 80px;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        .product-list{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 140px;
          z-index: 99999999999999;
          height: 0px;
          .product-list-item{
            width: 140px;
            height: 60px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
            font-weight: 400;
            color: #333333;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
            text-align: center;
          }
          .product-list-item:hover{
            color: #1E67FC;
            background: #F2F2F2;

          }
        }
      }
      .item-menu:hover {
        color: #1e67fc;
        font-weight: bold;
      }
      .router-link-active {
        color: #1e67fc;
        font-weight: bold;
      }
    }
  }
}


</style>