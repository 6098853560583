/**
 * 网络请求配置
 */
import axios from "axios";
import global from '../global/global'


//基础URL，axios将会自动拼接在url前
//process.env.NODE_ENV 判断是否为开发环境 根据不同环境使用不同的baseURL 方便调试
//let baseURL = process.env.NODE_ENV === 'development'? '' : 'http://oa.fuhua91.com/';
let baseURL = global.baseUrl;

//默认请求超时时间
const timeout = 30000;
let token = undefined


//创建axios实例
const service = axios.create({
  timeout,
  baseURL,
  //如需要携带cookie 该值需设为true
  withCredentials: true,
  headers:{
    "Content-Type": "application/json",
    
  }
});


 /**
  * http request 拦截器
  */

//token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVVUlEIjoiYjZlMjE5ZGUtMTM1YS00ZmE2LTk2NTYtZjI0MTRhZWUyZDBhIiwiSUQiOjEsIlVzZXJuYW1lIjoiYWRtaW4iLCJOaWNrTmFtZSI6Ik1yLuWlh-a3vCIsIkF1dGhvcml0eUlkIjo4ODgsIkF1dGhvcml0aWVzIjpbMSw4ODgsODg4MSw5NTI4XSwiQnVmZmVyVGltZSI6ODY0MDAsImV4cCI6MTY4MTU0NzQ3MCwiaXNzIjoicW1QbHVzIiwibmJmIjoxNjgwOTQxNjcwfQ.8YIxjKkAMRdzP7LfG7ZcQzSRNNr4-VWjYKgarFtSUK0"

service.interceptors.request.use(
  (config) => {
    if(localStorage.getItem('token')){
      token = localStorage.getItem('token')
    }
    config.headers = {
      "Content-Type": config.headers['Content-Type'],
      // "x-token":token
    };
    return config;
  },
  (error) => {
     return Promise.reject(error);
  }
 );
 
 /**
  * http response 拦截器
  */
service.interceptors.response.use(
   (response) => {
    
    
    return response.data;
   },
   (error) => {
     console.log("请求出错：", error);
   }
 );
export { service };